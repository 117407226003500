




















































































































































































































































// @ts-nocheck
import { Component, Vue } from 'vue-property-decorator';
import FarmHeader from "@/components/FarmHeader.vue";
import {FarmBucketState, FarmState, useFarmBucketState, useFarmState} from "@/store/farm";
import BucketList from "@/components/BucketList.vue";
import BucketView from "@/components/BucketView.vue";
import {storeToRefs} from "pinia";

@Component({
  components: {
    FarmHeader, BucketList, BucketView
  }
})
export default class App extends Vue {

  private farm: FarmState;
  private bucket: FarmBucketState;

  async created() {
    this.farm = useFarmState();
    this.bucket = storeToRefs(useFarmBucketState());
    await this.farm.initFarm();
    await this.farm.updateCommonInfo();

    await this.farm.checkStakedFarms();
    await this.farm.refreshBuckets();

    this.farm.getCurrentAccount
        .then((a: any) => {
          if (a) {
            this.farm.updateAddress(a);
            this.farm.updateBalances();
          }
        });

    await this.farm.periodicRefresh();
  }
}
