import {BeaconWallet, BeaconWalletNotInitialized, MissingRequiredScopes} from "@taquito/beacon-wallet";
import {PermissionScope} from "@airgap/beacon-sdk";
import type {DAppClientOptions} from "@airgap/beacon-sdk";

export class PatchedBeaconWallet extends BeaconWallet {

    constructor(options: DAppClientOptions) {
        // @ts-ignore
        super(options);
    }

    async sendOperations(params: any[]) {
        const account = await this.client.getActiveAccount();
        if (!account) {
            throw new BeaconWalletNotInitialized();
        }
        const permissions = account.scopes;
        this.overriddenValidateRequiredScopesOrFail(permissions, [
            PermissionScope.OPERATION_REQUEST,
        ]);

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const $this = this;
        const isClaimAll =
            params.length > 1 &&
            params.filter((op) => op.parameters.entrypoint === "claim").length > 1;
        const { transactionHash } = await this.client.requestOperation({
            operationDetails: isClaimAll
                ? params.map((op) => ({
                    ...$this.modifyFeeAndLimit(op),
                }))
                : params,
        });
        return transactionHash;
    }

    overriddenValidateRequiredScopesOrFail(
        permissionScopes: PermissionScope[],
        requiredScopes: PermissionScope[]
    ) {
        const mandatoryScope = new Set(requiredScopes);

        for (const scope of permissionScopes) {
            if (mandatoryScope.has(scope)) {
                mandatoryScope.delete(scope);
            }
        }

        if (mandatoryScope.size > 0) {
            throw new MissingRequiredScopes(Array.from(mandatoryScope));
        }
    }

    modifyFeeAndLimit(op: any) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fee, gas_limit, storage_limit, ...rest } = op;

        if (op.parameters && op.parameters.entrypoint === "claim") {
            rest.gas_limit = 100000;
            rest.fee = 20000;
        }
        return rest;
    }
}
