

























































// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {FarmBucketState, FarmState, useFarmBucketState, useFarmState} from "@/store/farm";
import BucketROI from "@/components/BucketROI.vue";
import BucketDetails from "@/components/BucketDetails.vue";
import BucketActions from "@/components/BucketActions.vue";
import BucketReferralInfo from "@/components/BucketReferralInfo.vue";
import TokenSelect from "@/components/TokenSelect.vue";
import {TokenInfo} from "@/model/TokenInfo";

@Component({
  components: {TokenSelect, BucketReferralInfo, BucketActions, BucketDetails, BucketROI},
  methods: {
    stakeMax: async function(token: TokenInfo) {
      this.bucketState.amount = this.bucketState.userBalance.get(token.id).toNumber();
    },
  }
})
export default class BucketView extends Vue {
  private farmState: FarmState;
  private bucketState: FarmBucketState;

  created() {
    this.farmState = useFarmState();
    this.bucketState = useFarmBucketState();
  }

  async prefillToUnstake(token) {
    this.bucketState.$patch({
      selectedToken: token,
      amount: this.bucketState.bucket.getUserTokenStackAmount(token.id).toNumber()
    });
    await this.bucketState.updateBalance();
  }
}
