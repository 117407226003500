import type { TezosToolkit } from "@taquito/taquito";
import {BigNumber} from "bignumber.js";
import {CacheContainer} from "node-ts-cache";
import {MemoryStorage} from "node-ts-cache-storage-memory";

const balanceCache = new CacheContainer(new MemoryStorage())

export class Common {
  static addOperatorOp(address: string, token_addr: string, token_id: number, contract: string) {
    return {
      kind: "transaction",
      destination: token_addr,
      amount: "0",
      parameters: {
        entrypoint: "update_operators",
        value: [
          {
            "prim": "Left",
            "args": [
              {
                "prim": "Pair",
                "args": [
                  {
                    "string": address
                  },
                  {
                    "prim": "Pair",
                    "args": [
                      {
                        "string": contract
                      },
                      {
                        "int": "" + token_id
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  }

  static async getUserBalance(
    tezos: TezosToolkit,
    userAddress: string,
    tokenType: string,
    tokenAddress: string,
    tokenFa2Id: string
  ): Promise<BigNumber> {
    const cachekey = `${userAddress}:${tokenType}:${tokenAddress}:${tokenFa2Id}`;
    const cached = await balanceCache.getItem<BigNumber>(cachekey);
    if (cached) {
      return cached;
    }
    let balance;
    switch (tokenType) {
      case "tz":
        balance = tezos.tz.getBalance(userAddress);
        break;
      case "FA2":
        try {
          const contract2 = await tezos.contract.at(tokenAddress);
          const natF2 = await contract2.views
            .balance_of([
              {
                owner: userAddress,
                token_id: tokenFa2Id,
              },
            ])
            .read();
          balance = natF2[0].balance;
        } catch(e) {
          console.log(e)
          balance = new BigNumber(0);
        }
        break;
      case "FA12":
        try {
          const contractF1_2 = await tezos.contract.at(tokenAddress);
          balance = await contractF1_2.views.getBalance(userAddress).read();
        } catch(e) {
          console.log(e)
          balance = new BigNumber(0);
        }
    }
    await balanceCache.setItem(cachekey, balance, {ttl: 10});
    return balance;
  }
}
