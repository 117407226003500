import BigNumber from "bignumber.js";
import {TokenAmount} from "@/model/TokenAmount";
import {PriceSource, TokenType} from "@/model/enums";

export type TokenInfo = {
    id: string;
    type: TokenType
    address: string;
    fa2Id: number;
    decimals: number;
    name: string;
    symbol: string;
    thumbnailUri: string;
    lp: boolean;
    priceSource: PriceSource;
    priceTez: BigNumber;
}

export function priceTezAmount(info: TokenInfo): TokenAmount {
    return new TokenAmount(info.priceTez.shiftedBy(2 * info.decimals - 6), info.decimals)
}
