import Vue from 'vue'
import App from './App.vue'
import "@/assets/main.css";
import {createPinia, PiniaVuePlugin} from 'pinia'
import VueCompositionAPI from '@vue/composition-api'


const pinia = createPinia()
Vue.use(VueCompositionAPI)
Vue.use(PiniaVuePlugin)

Vue.config.productionTip = false


new Vue({
  pinia,
  render: h => h(App)
}).$mount('#app')
