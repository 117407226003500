import BigNumber from "bignumber.js";

export const BN_ZERO = new BigNumber(0);
export const BN_INFINITY = new BigNumber('Infinity');

const humanReadableRepresentationMapping = [
  {
    threshold: Math.pow(10, 3),
    divider: 1,
    fractions: 0,
    label: ''
  },
  {
    threshold: Math.pow(10, 4),
    divider: Math.pow(10, 3),
    fractions: 2,
    label: 'k'
  },
  {
    threshold: Math.pow(10, 6),
    divider: Math.pow(10, 3),
    fractions: 0,
    label: 'k'
  },
  {
    threshold: Math.pow(10, 7),
    divider: Math.pow(10, 6),
    fractions: 2,
    label: 'M'
  },
  {
    threshold: Math.pow(10, 9),
    divider: Math.pow(10, 6),
    fractions: 0,
    label: 'M'
  },
  {
    threshold: Math.pow(10, 10),
    divider: Math.pow(10, 9),
    fractions: 2,
    label: 'B'
  },
  {
    threshold: Math.pow(10, 12),
    divider: Math.pow(10, 9),
    fractions: 0,
    label: 'B'
  },
  {
    threshold: Math.pow(10, 13),
    divider: Math.pow(10, 12),
    fractions: 2,
    label: 'T'
  },
  {
    threshold: Math.pow(10, 15),
    divider: Math.pow(10, 12),
    fractions: 0,
    label: 'T'
  },
  {
    threshold: Math.pow(10, 16),
    divider: Math.pow(10, 15),
    fractions: 2,
    label: 'Q'
  }
];

export class TokenAmount {
  amount: BigNumber;
  decimals: number;

  constructor(amount?: BigNumber, decimals?: number) {
    this.amount = amount ?? new BigNumber(0);
    this.decimals = decimals ?? 0;
  }

  toNormalized() {
    return this.amount.shiftedBy(-this.decimals);
  }

  toNumber() {
    return this.toNormalized().decimalPlaces(this.decimals).toNumber();
  }

  toString(): string {
    const value = this.toNormalized().decimalPlaces(this.decimals);
    return value.isFinite() ? value.toString() : '∞';
  }

  toPrettyString(): string {
    return this.toString();
  }

  static fromNormalizedNumber(val: number, decimals: number): TokenAmount {
    const bn = new BigNumber(val);
    return new TokenAmount(bn.shiftedBy(decimals), decimals);
  }

  static fromNormalized(val: BigNumber, decimals: number): TokenAmount {
    return new TokenAmount(val.shiftedBy(decimals), decimals);
  }
}
