import BigNumber from "bignumber.js";
import {BN_INFINITY, BN_ZERO, TokenAmount} from "./TokenAmount";
import {priceTezAmount, TokenInfo} from "@/model/TokenInfo";
import {MultiStakeBucketInfo} from "@/model/BucketInfo";
import {Common} from "@/utils/common-tezos";
import {TokenType} from "@/model/enums";
import {useFarmState} from "@/store/farm";

export const DEFAULT_RPS_PRECISION = new BigNumber('1000000000000000000000000');

export class MultiStakeFarmBucket {

  public totalStacks: Map<string, BigNumber>;

  constructor(public info: MultiStakeBucketInfo) {
    this.info.stakeTokens = info.stakeTokens;
    this.info.totalVirtualStack = info.totalVirtualStack;
    this.totalStacks = new Map<string, BigNumber>();
    if (info.totalStacks) {
      for (const totalStack of info.totalStacks) {
        this.totalStacks.set(totalStack.id, totalStack.value);
      }
    }
  }

  public getTotalStackAmount(id: string): TokenAmount {
    return new TokenAmount(this.totalStacks.get(id), this.resolveStakeToken(id).decimals);
  }

  public getTotalVirtualStackAmount(id: string): TokenAmount {
    return new TokenAmount(this.info.totalVirtualStack, this.resolveStakeToken(id).decimals);
  }

  private resolveStakeToken(id: string): TokenInfo {
    return this.info.stakeTokens.filter(t => t.id === id)[0];
  }

  public getTotalConsolidatedStack(): BigNumber {
    return this.info.totalStack.plus(this.info.totalVirtualStack);
  }

  public getTotalConsolidatedStackAmount(): TokenAmount {
    return new TokenAmount(this.getTotalConsolidatedStack(), 6);
  }

  public getTotalTokenStackAmount(id?: string): number {
    let result = 0;
    for (const totalStack of this.info.totalStacks) {
      if (id && totalStack.id === id) {
        result += Number(totalStack.value);
      } else if (!id) {
        result += Number(totalStack.value);
      }
    }
    return result;
  }

  public reward2StakeExchangeRate(id: string): BigNumber {
    if (!this.info.rewardToken.priceTez || this.info.rewardToken.priceTez.isZero()) {
      return BN_ZERO;
    }
    const stakeToken = this.resolveStakeToken(id);
    if (!stakeToken.priceTez || (!stakeToken.priceTez.isNaN() && !stakeToken.priceTez.isFinite())) {
      return BN_INFINITY;
    }
    return priceTezAmount(stakeToken).toNormalized().div(priceTezAmount(this.info.rewardToken).toNormalized());
  }

  // roi in tezos
  protected roiForDaysRate(days: number, id: string): BigNumber {
    if (this.info.stopped || !id) return BN_ZERO;
    const er = this.reward2StakeExchangeRate(id);
    if (!er.isFinite() || er.isZero()) {
      return BN_INFINITY;
    }
    const seconds = new BigNumber(60 * 60 * 24 * days);
    return seconds.multipliedBy(new TokenAmount(this.info.rewardsPerSecond, this.info.rewardToken.decimals).toNormalized())
        .div(er.multipliedBy(new BigNumber(this.getTotalTokenStackAmount(id))))
  }

  roiForDays(days: number, id: string): BigNumber | string {
    const value = this.roiForDaysRate(days, id).multipliedBy(100).decimalPlaces(2);
    return value.isFinite() ? value : '∞';
  }

  get endDate(): Date | null {
    if (!this.info.version || this.info.version < 4) {
      return null;
    }
    let endDateUnix = null;
    const rewardsPerSecond = this.info.rewardsPerSecond;
    if (!this.info.autoMint && rewardsPerSecond?.isGreaterThan(BN_ZERO) && this.info.rewardBalance && this.info.totalRewardsLeft) {
      const secLeft = this.info.rewardBalance
          .minus(this.info.totalRewardsLeft)
          .div(rewardsPerSecond).integerValue(BigNumber.ROUND_DOWN).toNumber();

      if (this.info.lastUpdateTime) {
        endDateUnix = new Date(this.info.lastUpdateTime.getTime() + secLeft * 1000);
      }
    }
    return endDateUnix;
  }

  public getUserTokenStackAmount(id: string): TokenAmount {
    const stakeToken = this.resolveStakeToken(id);
    if (!this.info.userPosition || !this.info.userPosition.tokenStacks.filter(s => s.id === id).length) {
      return new TokenAmount(BN_ZERO, stakeToken.decimals)
    }
    return new TokenAmount(this.info.userPosition.tokenStacks.filter(s => s.id === id)[0].value, stakeToken.decimals);
  }

  async updateSelf() {
    const farm = useFarmState();
    const farmBucket = await farm.apiClient?.fetchFarm(this.info.id, await farm.getCurrentAccountAddress)
    this.info = Object.assign({}, farmBucket);
  }

  public farmPercentNumber(address: string): number {
    if (this.info.userPosition && this.info.userPosition.tokenStacks.filter(s => s.id === address).length) {
      return this.info.userPosition.tokenStacks.filter(s => s.id === address)[0].value.div(this.getTotalConsolidatedStack()).multipliedBy(100).decimalPlaces(9).toNumber();
    } else {
      return 0;
    }
  }

  rewardsPerStaked(days: number): TokenAmount {
    if (this.info.stopped) return new TokenAmount(BN_ZERO, this.info.rewardToken.decimals);
    return TokenAmount.fromNormalized(
        new TokenAmount(
            this.info.rewardsPerSecond.multipliedBy(60 * 60 * 24 * days), this.info.rewardToken.decimals
        )
            .toNormalized()
            .div(new BigNumber(this.getTotalTokenStackAmount())),
        this.info.rewardToken.decimals
    );
  }

  private _calcRewards() {
    const now = new BigNumber(new Date().getTime() / 1000).integerValue(BigNumber.ROUND_DOWN);

    const state = {
      last_update_time: new BigNumber(new Date(this.info.lastUpdateTime).getTime() / 1000).integerValue(BigNumber.ROUND_DOWN),
      reward_amount_per_sec: this.info.rewardsPerSecond,
      reward_per_stake: this.info.rewardsPerStake,
      consolidated_stack: this.getTotalConsolidatedStack(),
      reward_balance: this.info.rewardBalance,
      total_rewards_left: this.info.totalRewardsLeft,
      stopped: this.info.stopped,
      automint: this.info.autoMint,
      rps_precision: DEFAULT_RPS_PRECISION
    }

    if (!state.consolidated_stack.isZero()) {
      let eff_rewards_from_last_update = state.stopped ? new BigNumber(0) : now.minus(state.last_update_time).multipliedBy(state.reward_amount_per_sec);
      if (!state.automint && (eff_rewards_from_last_update.plus(state.total_rewards_left).isGreaterThan(state.reward_balance))) {
        eff_rewards_from_last_update = state.reward_balance.minus(state.total_rewards_left);
        state.stopped = true;
      }
      state.reward_per_stake = state.reward_per_stake.plus(eff_rewards_from_last_update.multipliedBy(state.rps_precision).div(state.consolidated_stack));
      state.total_rewards_left = state.total_rewards_left.plus(eff_rewards_from_last_update);
    }
    const reward_increase = this.info.userPosition.stack.plus(this.info.userPosition.virtualStack)
        .multipliedBy(state.reward_per_stake.minus(this.info.userPosition.lastRewardsPerStake))
        .div(state.rps_precision);
    return this.info.userPosition.rewards.plus(reward_increase);
  }

  get rewards(): BigNumber {
    //console.log(`Calc rewards: ${JSON.stringify(this.userPosition)}, version = ${this.version}`)
    if (!this.info.userPosition) {
      return BN_ZERO;
    }
    return this._calcRewards()
  }

  rewardsAmount(): TokenAmount {
    return new TokenAmount(this.rewards, this.info.rewardToken.decimals);
  }

  private _callFa12Approve(token_addr: string, amount: string, contract?: string) {
    return {
      kind: "transaction",
      destination: token_addr,
      amount: "0",
      parameters: {
        entrypoint: "approve",
        value: {
          "prim": "Pair",
          "args": [
            {
              "string": contract ?? this.info.id
            },
            {
              "int": "" + amount
            }
          ]
        }
      }
    }
  }

  async stake(amount: BigNumber, address: string, tokenId: string, upline: string) {
    console.log(`Stake ${amount.toString()} of ${tokenId} for ${address} and upline=${upline}`);
    const amountStr = "" + amount.decimalPlaces(0).toString();
    const ops = [];
    const stakeToken = this.resolveStakeToken(tokenId);
    if (stakeToken.type === TokenType.FA12) {
      ops.push(this._callFa12Approve(stakeToken.address, amountStr));
    } else {
      ops.push(Common.addOperatorOp(address, stakeToken.address, stakeToken.fa2Id, this.info.id));
    }
    let parameters;
    if (upline) {
      parameters = {
        "prim": "Pair",
        "args": [
          {
            "prim": "Pair",
            "args": [
              {
                "prim": "Pair",
                "args": [
                  {
                    "string": stakeToken.address
                  },
                  {
                    "int": stakeToken.fa2Id.toString()
                  }
                ]
              },
              {
                "int": amountStr
              }
            ]
          },
          {
            "prim": "Some",
            "args": [
              {
                "string": upline
              }
            ]
          }
        ]
      };
    } else {
      parameters = {
        "prim": "Pair",
        "args": [
          {
            "prim": "Pair",
            "args": [
              {
                "prim": "Pair",
                "args": [
                  {
                    "string": stakeToken.address
                  },
                  {
                    "int": "" + stakeToken.fa2Id.toString()
                  }
                ]
              },
              {
                "int": amountStr
              }
            ]
          },
          {
            "prim": "None"
          }
        ]
      };
    }
    ops.push({
      kind: "transaction",
      destination: this.info.id,
      amount: "0",
      parameters: {
        entrypoint: "stake",
        value: parameters
      }
    });
    const farm = useFarmState();
    return await farm.wallet?.sendOperations(ops)
  }

  async unstake(amount: BigNumber, address: string, tokenAddress: string, id: number) {
    console.log(`Unstake ${amount.toString()} of ${tokenAddress} for ${address}`)
    const ops = [
      {
        kind: "transaction",
        destination: this.info.id,
        amount: "0",
        parameters: {
          entrypoint: "unstake",
          value: {
            "prim": "Pair",
            "args": [
              {
                "prim": "Pair",
                "args": [
                  {
                    "string": tokenAddress
                  },
                  {
                    "int": "" + id.toString()
                  }
                ]
              },
              {
                "int": "" + amount.toString()
              }
            ]
          }
        }
      }
    ]
    const farm = useFarmState();
    return await farm.wallet?.sendOperations(ops)
  }

  async claim() {
    const ops = [this.createClaimOps()];
    const farm = useFarmState()
    console.log(JSON.stringify(ops));
    return await farm.wallet?.sendOperations(ops)
  }

  createClaimOps() {
    return {
      kind: "transaction",
      destination: this.info.id,
      amount: "0",
      parameters: {
        entrypoint: "claim",
        value: {
          "prim": "Unit"
        }
      }
    };
  }
}
