

















// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {TokenInfo} from "@/model/TokenInfo";
import {useFarmBucketState} from "@/store/farm";

@Component
export default class TokenSelect extends Vue {
  private dropDownShown: boolean = false;
  private selectedTokenName: string = '';
  @Prop() private stakeTokens: TokenInfo[];

  public toggleDropdown() {
    this.dropDownShown = !this.dropDownShown;
  }

  public async selectStakeToken(token: TokenInfo) {
    this.toggleDropdown();
    this.selectedTokenName = token.info.name;
    const bucketState = useFarmBucketState();
    bucketState.$patch({
      selectedToken: token
    });
    await bucketState.updateBalance();
  }
}
