














import Vue from "vue";
import Component from "vue-class-component";
import {useFarmBucketState} from "@/store/farm";

@Component({
  methods: {
    selectBucket: async function(id: string) {
      const bucketState = useFarmBucketState();
      await bucketState.select(id);
    }
  },
  props: ['bucket']
})
export default class BucketPreview extends Vue {
}
