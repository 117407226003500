








































// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {MultiStakeFarmBucket} from "@/model/MultiStakeFarmBucket";

@Component
export default class BucketDetails extends Vue {
  @Prop() private bucket: MultiStakeFarmBucket;
}
