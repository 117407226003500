























































// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {MultiStakeFarmBucket} from "@/model/MultiStakeFarmBucket";
import {useFarmBucketState} from "@/store/farm";
import {TokenInfo} from "@/model/TokenInfo";

@Component({
  methods: {
    rewardsPerToken(days: number) {
      return this.bucket.rewardsPerStaked(days)
    }
  },
})
export default class BucketROI extends Vue {
  @Prop() private bucket: MultiStakeFarmBucket;
  private selectedToken: TokenInfo = null;

  created() {
    const {selectedToken} = useFarmBucketState();
    this.bucketState =  useFarmBucketState();
    this.selectedToken = selectedToken;
  }
}
