export function fixFloat(val: number, decimals: number): number {
  return parseFloat(val.toFixed(decimals).toString())
}

export function natToNumber(val: any, decimals: number): number {
  if (typeof val !== "number") {
    val = val.toNumber();
  }
  return fixFloat(val / Math.pow(10, decimals), decimals);
}
