
// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import Account from "@/components/Account.vue";
import AccountBalances from "@/components/AccountBalances.vue";
import TotalValueLocked from "@/components/TotalValueLocked.vue";
import {FarmState, useFarmState} from "@/store/farm";
@Component({
  components: {
    Account, AccountBalances, TotalValueLocked
  }
})
export default class FarmHeader extends Vue {
  farmState: FarmState;

  created() {
    this.farmState = useFarmState();
  }
}
