
// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {FarmState, useFarmState} from "@/store/farm";

@Component
export default class TotalValueLocked extends Vue {
  farmState: FarmState;

  created() {
    this.farmState = useFarmState();
  }
}
