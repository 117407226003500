












// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {FarmBucketState, FarmState, useFarmBucketState, useFarmState} from "@/store/farm";
import BucketFilter from "@/components/BucketFilter.vue";
import BucketPreview from "@/components/BucketPreview.vue";

@Component({
  components: {BucketFilter, BucketPreview}
})
export default class BucketList extends Vue {
  private farmState: FarmState;
  private bucketState: FarmBucketState;

  created() {
    this.farmState = useFarmState();
    this.bucketState = useFarmBucketState();
  }
}
