



















// @ts-nocheck
import Component from "vue-class-component";
import Vue from "vue";
import {FarmState, useFarmState} from "@/store/farm";

@Component({
  methods: {
    refreshBuckets: async function(type) {
      const farmState = useFarmState();
      farmState.typeFilter = type;
      await farmState.refreshBuckets();
    },
    claimAll: async function () {
      const farmState = useFarmState();
      await farmState.claimAll();
    },
  }
})
export default class BucketFilter extends Vue {
  private farmState: FarmState;

  created() {
    this.farmState = useFarmState();
  }
}
