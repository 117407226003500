import BigNumber from "bignumber.js";
import {gql, GraphQLClient} from "graphql-request";

export interface FarmFilter {
    visible?: boolean;
    userId?: string;
    deprecated?: boolean;
    lp?: boolean;
    stakePriceSource: string;
    multiStake: boolean;
}

function _objIterate(obj: any, fun: any, names: string[], converter: any) {
    Object.keys(obj).forEach((k) => {
        obj[k] = fun(k, obj[k], names, converter);
    });
    return obj;
}

export function deeplyConvert<T>(
    name: string,
    obj: T,
    names: string[],
    converter: any
): any {
    if (obj !== null && typeof obj === "string" && names.includes(name)) {
        return converter(obj);
    }
    if (obj !== null && Array.isArray(obj)) {
        return obj.map((v) => deeplyConvert(name, v, names, converter));
    } else if (typeof obj === "object" && obj !== null && obj !== undefined) {
        return _objIterate(obj, deeplyConvert, names, converter);
    }
    return obj;
}

export class ApiClient {
    apiUrl: string;
    graphqlClient: GraphQLClient;

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
        this.graphqlClient = new GraphQLClient(apiUrl);
    }

    async fetchSpaceFarm() {
        const res = await this.graphqlClient.request(gql`
      {
        spaceFarm {
          farmsCount
          totalStackTez
        }
        token(id: "fa2:KT1Wa8yqRBpFCusJWgcQyjhRz7hUQAmFxW7j:0") {
          priceTez
        }
      }
    `);
        res.spaceFarm.totalStackTez = new BigNumber(res.spaceFarm.totalStackTez);
        res.spaceFarm.flamePrice = new BigNumber(res.token.priceTez).decimalPlaces(
            6
        );
        return res.spaceFarm;
    }

    async fetchFarms(filter: FarmFilter) {
        const userPositionFragment = filter.userId
            ? `
      userPosition(userId: "${filter.userId}") {
        stack
        virtualStack
        rewards
        firstStake
        lastRewardsPerStake
        upline 
        tokenStacks {
            id
            value
        }
      }
    `
            : "";
        const stakeTokenFragment = `stakeToken${filter.multiStake ? 's' : ''}`;
        const farmsRes = await this.graphqlClient.request(
            gql`
      query getFarms($filter: FarmFilter){
        farms(filter: $filter) {
          items {
            id
            name
            img
            deprecated
            noclaim
            totalStacks {
                id
                value
            }
            totalStack
            totalVirtualStack
            rewardBalance
            totalRewardsLeft
            totalFees
            totalDepositFees
            rewardsPerSecond
            rewardsPerStake
            lastUpdateTime
            autoMint
            stopped
            ${stakeTokenFragment} {
              id
              lp
              priceSource
              priceTez
              info {
                symbol
                name
                decimals
              }
            }
            rewardToken {
              id
              lp
              priceSource
              priceTez
              info {
                symbol
                name
                decimals
              }
            }
            ${userPositionFragment}
          }
        }
      }
    `,
            {filter: filter}
        );

        let convertedFarms = deeplyConvert(
            "",
            farmsRes.farms,
            [
                "priceTez",
                "rewardsPerSecond",
                "rewardBalance",
                "totalRewardsLeft",
                "rewardsPerStake",
                "totalStack",
                "totalVirtualStack",
                "totalFees",
                "totalDepositFees",
                "stack",
                "tokenStack",
                "virtualStack",
                "rewards",
                "lastRewardsPerStake",
                "value"
            ],
            (v: any) => new BigNumber(v)
        );

        convertedFarms = deeplyConvert(
            "",
            convertedFarms,
            ["lastUpdateTime", "firstStake"],
            (v: any) => new Date(v)
        );

        convertedFarms.items = convertedFarms.items.filter(
            (i: any) => {
                for (const stakeToken of i.stakeTokens) {
                    if (!ApiClient._defined(stakeToken.info)) {
                        return false;
                    }
                }
                return ApiClient._defined(i.rewardToken.info);
            }
        );

        for (const item of convertedFarms.items) {
            item.stakeTokens.sort((a: any, b: any) => a.info.name.localeCompare(b.info.name));
            for (const stakeToken of item.stakeTokens) {
                stakeToken.decimals = stakeToken.info.decimals;
            }
            item.rewardToken.decimals = item.rewardToken.info.decimals;
        }

        return convertedFarms;
    }

    private static _defined(val: any) {
        return val !== undefined && val !== null;
    }

    async fetchFarm(farmId: string, userId?: string) {
        const userPositionFragment = userId
            ? `
      userPosition(userId: "${userId}") {
        stack
        virtualStack
        rewards
        firstStake
        lastRewardsPerStake
        upline
        tokenStacks {
            id
            value
        }
      }
    `
            : "";
        const farmRes = await this.graphqlClient.request(
            gql`
    query getFarm($id: String!){
      farm(id: $id) {
        id
        name
        img
        deprecated
        noclaim
        version
        totalStacks {
            id
            value
        }
        totalStack
        totalVirtualStack
        lastUpdateTime
        rewardBalance
        totalRewardsLeft
        totalFees
        totalDepositFees
        feesCfg {
          fee
          fromSecs
        }
        depositFeesCfg {
          fee
          fromSecs
        }
        rewardsPerSecond
        rewardsPerStake
        autoMint
        stopped
        links {
          title
          url
        }
        stakeTokens {
          id
          type
          address
          fa2Id
          lp
          priceSource
          priceTez
          info {
            symbol
            name
            decimals
          }
        }
        rewardToken {
          id
          type
          address
          fa2Id
          lp
          priceSource
          priceTez
          info {
            symbol
            name
            decimals
          }
        }
        ${userPositionFragment}
      }
    }
  `,
            {id: farmId}
        );

        for (const stakeToken of farmRes.farm.stakeTokens) {
            stakeToken.decimals = stakeToken.info.decimals;
        }
        farmRes.farm.rewardToken.decimals = farmRes.farm.rewardToken.info.decimals;

        let convertedFarm = deeplyConvert(
            "",
            farmRes.farm,
            [
                "priceTez",
                "rewardsPerSecond",
                "rewardBalance",
                "totalRewardsLeft",
                "rewardsPerStake",
                "totalFees",
                "totalDepositFees",
                "totalStack",
                "totalVirtualStack",
                "stack",
                "tokenStack",
                "value",
                "virtualStack",
                "rewards",
                "lastRewardsPerStake",
            ],
            (v: any) => new BigNumber(v)
        );

        convertedFarm = deeplyConvert(
            "",
            convertedFarm,
            ["lastUpdateTime", "firstStake"],
            (v: any) => new Date(v)
        );
        convertedFarm.stakeTokens.sort((a: any, b: any) => a.info.name.localeCompare(b.info.name));

        return convertedFarm;
    }
}
