












// @ts-nocheck
import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {FarmBucketState, FarmState, useFarmBucketState, useFarmState} from "@/store/farm";
import {MultiStakeFarmBucket} from "@/model/MultiStakeFarmBucket";

@Component
export default class BucketReferralInfo extends Vue {
  @Prop() private bucket: MultiStakeFarmBucket;
  private farmState: FarmState;
  private bucketState: FarmBucketState;

  created() {
    this.farmState = useFarmState();
    this.bucketState = useFarmBucketState();
  }
}
