
// @ts-nocheck
import Component from "vue-class-component";
import Vue from "vue";
import {FarmState, useFarmState} from "@/store/farm";

@Component()
export default class Account extends Vue {
  farmState: FarmState;

  created() {
    this.farmState = useFarmState();
  }
}
