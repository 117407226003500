// @ts-nocheck
import {defineStore} from "pinia";
import {TezosToolkit} from "@taquito/taquito";
import {BeaconWallet} from "@taquito/beacon-wallet";
import type {DAppClientOptions} from "@airgap/beacon-sdk";
import {InMemorySigner} from "@taquito/signer";
import {FarmStats} from "@/model/farmStats";
import {BalanceStats} from "@/model/BalanceStats";
import {ApiClient, FarmFilter} from "@/model/ApiClient";
import {FarmConfig} from "@/model/farmConfig";
import {PatchedBeaconWallet} from "@/model/PatchedBeaconWallet";
import {Common} from "@/utils/common-tezos";
import {TokenAmount} from "@/model/TokenAmount";
import BigNumber from "bignumber.js";
import {fixFloat, natToNumber} from "@/utils/common-numbers";
import commaNumber from "comma-number";
import {MultiStakeFarmBucket} from "@/model/MultiStakeFarmBucket";
import {TokenInfo} from "@/model/TokenInfo";

export type FarmBucketState = {
  amount: number;
  userBalance?: Map<string, TokenAmount>;
  inProgress: boolean;
  selectedToken: TokenInfo;
  bucket?: MultiStakeFarmBucket;
}

export type FarmState = {
  address: string | null;
  addressDisplay: string;
  balances: BalanceStats;
  typeFilter: string | null;
  searchToken: string;
  buckets: MultiStakeFarmBucket[];
  inProgress: boolean;
  stakedFarmsPresent: boolean;
  stats: FarmStats;
  burnt: number;
  supply: number;
  network?: any;
  token?: any;
  indexerUrl?: string;
  apiClient?: ApiClient;
  taq?: TezosToolkit;
  wallet?: BeaconWallet;
};

export const configs: Map<string, { farm: string }> = new Map<
  string,
  { farm: string }
>([
  ["mainnet", { farm: "api/farm.json" }],
  ["testnet", { farm: "api/testnet-farm.json" }],
  ["localnet", { farm: "api/farm.json" }],
]);

export const useFarmState = defineStore("farmState", {
  state: (): FarmState => {
    return {
      address: null,
      addressDisplay: "",
      balances: { token: 0, tezos: 0 },
      typeFilter: null,
      searchToken: "",
      buckets: [],
      inProgress: false,
      stakedFarmsPresent: false,
      stats: {
        tvl: {
          tz: 0,
          usd: 0,
        },
        flamePrice: 0,
      },
      burnt: 0,
      supply: 0,
    };
  },
  actions: {
    async initFarm(): Promise<void> {
      let netName = "mainnet";
      const testnetFlag = new URLSearchParams(window.location.search).get(
        "testnet"
      );
      if (testnetFlag && testnetFlag === "true") {
        netName = "testnet";
      }
      if (window.location.href.indexOf("localhost") > -1) {
        netName = "localnet";
      }

      const farmUrl = configs.get(netName) || { farm: "api/farm.json" };
      const conf: FarmConfig = await fetch(farmUrl.farm, {
        cache: "no-cache",
      }).then((r) => r.json());
      this.taq = new TezosToolkit(conf.network.rpc);
      const options: DAppClientOptions = {
        name: "Space Farm",
        preferredNetwork: conf.network.type,
      };
      this.wallet = new PatchedBeaconWallet(options);
      this.taq.setWalletProvider(this.wallet);

      this.taq.setSignerProvider(
        await InMemorySigner.fromSecretKey(
          "edsk3QoqBuvdamxouPhin7swCvkQNgq4jP5KZPbwWNnwdZpSpJiEbq"
        )
      );
      this.network = conf.network;
      this.token = conf.token;
      this.indexerUrl = conf.indexerUrl;
      this.apiClient = new ApiClient(conf.apiUrl);
      Object.assign(this, conf);
    },

    async getBucket(contract: string): Promise<{ farm: any }> {
      if (!this.wallet || !this.apiClient) {
        return Promise.reject("No wallet present");
      }
      const account = await this.wallet.client.getActiveAccount();
      return {
        farm: await this.apiClient.fetchFarm(
          contract,
          account ? account.address : undefined
        ),
      };
    },

    async listBuckets(type: "single" | "staked" | "nft"): MultiStakeFarmBucket[] {
      if (!this.wallet || !this.apiClient) {
        return Promise.reject("No wallet");
      }
      console.log(`List buckets ${type}`);
      const filter: FarmFilter = { visible: true, multiStake: true, deprecated: false };
      if (type === "staked") {// eslint-disable-next-line no-case-declarations
        const account = await this.wallet.client.getActiveAccount();
        if (account) {
          filter.userId = account.address;
        } else {
          return [];
        }
      }
      const farmsRes = await this.apiClient.fetchFarms(filter);
      return farmsRes.items
          .filter((f: any) => (type === "staked" ? !f.noclaim : true))
          .map((f: any) => {
            return new MultiStakeFarmBucket(f);
          });
    },

    async doLogout() {
      if (this.wallet) {
        this.wallet.clearActiveAccount();
      }
    },

    async doLogin() {
      if (this.wallet) {
        await this.wallet.requestPermissions({
          network: {
            name: this.network.name,
            rpcUrl: this.network.rpc,
            type: this.network.type,
          },
        });
        return await this.wallet.client.getActiveAccount();
      }
    },

    async findBalance(address: string, token: TokenInfo) {
      if (this.taq) {
        const balance = await Common.getUserBalance(
            this.taq,
            address,
            token.type,
            token.address,
            token.fa2Id
        );
        console.log(`Found balance ${token.type}:${token.address}:${token.fa2Id} : ${balance}`);
        return new TokenAmount(balance, token.decimals);
      }
    },

    async getXtzUsdPrice() {
      const tezosPriceRequest = await fetch(
        "https://api.coinbase.com/v2/prices/XTZ-USD/spot"
      );
      const tezosPriceResponse = await tezosPriceRequest.json();
      return new BigNumber(tezosPriceResponse.data.amount);
    },

    async getSpacefarmCommonStats() {
      if (this.apiClient) {
        const [spaceFarmStats, xtzPrice] = await Promise.all([
          this.apiClient.fetchSpaceFarm(),
          this.getXtzUsdPrice(),
        ]);
        return {
          tvl: {
            tz: commaNumber(
                spaceFarmStats.totalStackTez.decimalPlaces(0).toNumber()
            ),
            usd: commaNumber(
                fixFloat(spaceFarmStats.totalStackTez.multipliedBy(xtzPrice), 0)
            ),
          },
          flamePrice: spaceFarmStats.flamePrice,
        };
      }
    },

    //

    async periodicRefresh() {
      try {
        const promises = [];
        if (this.address) {
          const bucketState = useFarmBucketState();
          promises.push(bucketState.updateInfo());
          promises.push(this.updateBalances());
        }
        promises.push(this.updateCommonInfo());
        await Promise.all(promises)
      } catch(e) {
        console.error(e);
      }
      setTimeout(() => this.periodicRefresh(), 10000)
    },

    async refreshBuckets() {
      const buckets = await this.listBuckets("nft");
      this.buckets = buckets.filter((b) => b.info.name.toLowerCase().indexOf(this.searchToken.toLowerCase()) > -1);
    },

    async claimAll() {
      const stakedFarms = await this.listBuckets('staked');
      const ops = [];
      for (const stakedFarm of stakedFarms) {
        const rewards = stakedFarm.rewards();
        console.log(`Rewards for ${stakedFarm.name} ${rewards}`)
        if (!rewards.isZero() && !stakedFarm.noclaim) {
          console.log(`Added ${stakedFarm.name} to claimAll`)
          ops.push(stakedFarm.createClaimOps());
        }
      }
      await this.wallet.sendOperations(ops);
    },

    updateAddress(account) {
      this.address = account.address
      this.addressDisplay = account.address.substring(0, 5) + "..." + account.address.substring(account.address.length - 5);
    },

    showList() {
      const bucketState = useFarmBucketState();
      bucketState.$reset();
    },

    async updateBalances() {
      try {
        this.balances = await this.getBalanceAndInfo;
      } catch(e) {
        console.error(e);
      }
    },

    async updateCommonInfo() {
      try {
        this.stats = await this.getSpacefarmCommonStats();
        this.burnt = await this.getBurnt;
        this.supply = await this.getSupply;
      } catch(e) {
        console.error(e);
      }
    },

    resetState() {
      this.balances = { token: 0, tezos: 0 }
    },

    async login() {
      const acc = await this.doLogin();
      this.updateAddress(acc);
      await this.updateBalances();
      const bucketState = useFarmBucketState();
      await bucketState.updateInfo();
      await this.checkStakedFarms();
    },

    async logout() {
      await this.doLogout();
      this.address = null;
      this.resetState();
      const bucketState = useFarmBucketState();
      await bucketState.updateInfo();
    },

    async checkStakedFarms() {
      const stakedFarms = await this.listBuckets('staked');
      this.stakedFarmsPresent = stakedFarms.length > 0;
    }
  },
  getters: {
    getBurnt: async (state) => {
      const burntRequest = await fetch(
        `${state.indexerUrl}/api/rest/burnt?token=KT1Wa8yqRBpFCusJWgcQyjhRz7hUQAmFxW7j`
      );
      const burntResponse = (await burntRequest.json()) || {};
      return commaNumber(
        fixFloat((burntResponse.burnt[0].amount || 0) / Math.pow(10, 6), 0)
      );
    },

    getSupply: async (state) => {
      if (state.taq) {
        const contract = await state.taq.contract.at(state.token.addr);
        const storage: any = await contract.storage();
        return commaNumber(
          fixFloat(natToNumber(storage["total_supply"], state.token.decimals), 0)
        );
      }
    },

    getCurrentAccount: async (state) => {
      if (state.wallet) {
        return await state.wallet.client.getActiveAccount();
      }
    },

    getCurrentAccountAddress: async (state) => {
      if (state.wallet) {
        const account = await state.wallet.client.getActiveAccount();
        return account?.address;
      }
    },

    getBalanceAndInfo: async (state) => {
      if (!state.wallet || !state.taq) {
        return { token: 0, tezos: 0 };
      }
      // FIXME need to use public method of FA2 and lambda view
      const activeAccount = await state.wallet.client.getActiveAccount();
      if (!activeAccount) {
        return { token: 0, tezos: 0 };
      }
      const flameContract = await state.taq.contract.at(state.token.addr);
      const flameStorage: any = await flameContract.storage();
      const accStorage = await flameStorage.ledger.get(activeAccount.address);
      return {
        token: accStorage
          ? natToNumber(accStorage.balance, state.token.decimals)
          : 0,
        tezos: natToNumber(
          await state.taq.tz.getBalance(activeAccount.address),
          6
        ),
      };
    },
  },
});

export const useFarmBucketState = defineStore("farmBucketState", {
  state: (): FarmBucketState => {
    return {
      inProgress: false,
      amount: 0,
      bucket: null,
      selectedToken: null,
      userBalance: undefined
    };
  },
  actions: {
    async select(contract: string) {
      const farmState = useFarmState();
      const {farm} = await farmState.getBucket(contract);
      this.bucket = new MultiStakeFarmBucket(farm);
      await this.updateInfo();
    },

    async updateInfo() {
      console.log('updateInfo');
      if (this.bucket) {
        await this.bucket.updateSelf();
        await this.updateBalance();
      }
    },

    async updateBalance() {
      const farm = useFarmState();
      const currentAccountAddress = await farm.getCurrentAccountAddress;
      if (this.bucket && currentAccountAddress) {
        if (!this.userBalance) {
          this.userBalance = new Map<string, TokenAmount>();
        }
        for (const stakeToken of this.bucket.info.stakeTokens) {
          this.userBalance.set(stakeToken.id, await farm.findBalance(currentAccountAddress, stakeToken))
        }
      }
    },

    getAmountAsTokenAmount(): TokenAmount {
      if (this.bucket) {
        return TokenAmount.fromNormalizedNumber(this.amount, this.selectedToken.decimals);
      }
      return new TokenAmount();
    },

    roiForDays(days: number) {
      return this.bucket.roiForDays(days).toString()
    },

    async runInProgress(f: () => void) {
      this.inProgress = true;
      try {
        await f();
      } finally {
        this.inProgress = false;
      }
    },

    async stake() {
      if (this.amount === null || Number.isNaN(this.amount)) return;

      const farm = useFarmState();
      await this.runInProgress(async () => {
        const account = await farm.getCurrentAccount;
        if (account && this.bucket) {
          await this.bucket.stake(this.getAmountAsTokenAmount().amount, account.address, this.selectedToken.id, (window as any).refAddress);
          this.amount = 0;
        }
      })
    },

    async unstake() {
      if (this.amount === null || Number.isNaN(this.amount)) return;

      const farm = useFarmState();
      this.runInProgress(async () => {
        const account = await farm.getCurrentAccount;
        if (account && this.bucket) {
          await this.bucket.unstake(this.getAmountAsTokenAmount().amount, account.address, this.selectedToken.address,
              this.selectedToken.fa2Id);
          this.amount = 0;
        }
      });
    },

    async claim() {
      await this.runInProgress(async () => {
        await this.bucket.claim()
      });
    }
  }
});
